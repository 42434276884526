import React from 'react';
import styled from "styled-components";


/**
 타임라인 영역 (Audio-Wave, 앵커, 자막 Box, Zoom-in/out)

 * @param data1 설명
 * @return {data1} 반환
 * @throws ValidException 예외 발생
 * @author plaq99@rumpus.co.kr
 * @date 03-20-2023
 */

export default function TimeLineBox() {
  return (
    <>
      <TimeLineArea>
        <div className="timeline">
          <div className="timeline_tool_area">

            <div className="button_area">
              <button type="button" className="btn_split">추가</button>
              <button type="button" className="btn_split">분할</button>
              <button type="button" className="btn_split">삭제</button>
            </div>

            <div className="zoom_area">
              <label htmlFor="timeline_zoom" className="hide">timeline zoom lavel</label>
              <input
                className="tmp_btn_zoom_slider"
                type="range"
                min={0}
                max={100}
                defaultValue={20}
                step={10}
              />
            </div>
          </div>
          <div className="timeline_area">
            <div className="timeline_viewport">
              {/*타임라인 zoom에 따라 계산하여 모든 left, width 값 재설정 필요합니다.*/}
              <div className="timeline_content" style={{width: "3600px"}} id="timeline_content">
                {/*눈금 1칸: 10px*/}
                <div className="timeline_grid">
                  <span className="val" style={{left: 0}}>0:01</span>
                  <span className="val" style={{left: "100px"}}>0:02</span>
                  <span className="val" style={{left: "200px"}}>0:03</span>
                  <span className="val" style={{left: "300px"}}>0:04</span>
                </div>
                <div className="timeline_current_bar" style={{left: "100px"}}></div>
                {/*caption boxs*/}
                <div className="timeline_caption ui-draggable ui-draggable-handle ui-resizable"
                     style={{left: "113px", width: "120px", top: "35px"}}>
                  <div
                    className="resize_handle resize_handle_left ui-resizable-handle ui-resizable-w">
                    <span className="label">00:04.90</span>
                  </div>
                  <div
                    className="resize_handle resize_handle_right ui-resizable-handle ui-resizable-e">
                    <span className="label">00:05.20</span>
                  </div>
                  <div className="caption_txt">#1 1.09s<br/> 자막내용1... <br/> <br/></div>
                </div>

                <div className="timeline_caption ui-draggable ui-draggable-handle ui-resizable"
                     style={{left: "274px", width: "200px", top: "35px"}}>
                  <div
                    className="resize_handle resize_handle_left ui-resizable-handle ui-resizable-w">
                    <span className="label">00:04.90</span>
                  </div>
                  <div
                    className="resize_handle resize_handle_right ui-resizable-handle ui-resizable-e">
                    <span className="label">00:05.20</span>
                  </div>
                  <div className="caption_txt">#2 3.19s<br/> 자막내용2 자막내용2 <br/> <br/></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TimeLineArea>
    </>
  );
}


const TimeLineArea = styled.div`
  .timeline {
    background-color: #222;
  }
  
  .timeline .timeline_tool_area {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: space-between;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: space-between;
    padding: 15px 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.13);
  }

  .timeline .timeline_tool_area .button_area .btn_split {
    width: 85px;
    height: 32px;
    margin-left: 5px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: #0a87ff;
    font-size: 14px;
    font-weight: bold;
  }


  .timeline .timeline_tool_area .zoom_area {
    width: 100px;
    height: 5px;
  }

  .timeline .timeline_tool_area .tmp_btn_zoom_slider {
    width:110px;
    height:10px;
  }
  

  .timeline .timeline_area .timeline_viewport {
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;
    padding: 0 20px 20px;
  }

  .timeline .timeline_area .timeline_viewport::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  .timeline .timeline_area .timeline_viewport::-webkit-scrollbar-thumb {
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background: #0a87ff;
  }

  .timeline .timeline_area .timeline_viewport::-webkit-scrollbar-track-piece {
    background: #1f1f1f;
  }

  .timeline .timeline_area .timeline_content {
    position: relative;
    height: 100px;
  }

  .timeline .timeline_area .timeline_grid {
    position: relative;
    width: 100%;
    height: 21px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23474749' width='50' height='6' viewBox='0 0 50 6'%3E%3Cpath d='M0,0h1v6H0V0z M10,3h1V0h-1V3z M20,3h1V0h-1V3z M30,3h1V0h-1V3z M40,0v3h1V0H40z'/%3E%3C/svg%3E");
    background-repeat: repeat-x;
    background-position: 0 0;
  }

  .timeline .timeline_area .timeline_grid .val {
    position: absolute;
    top: 5px;
    font-size: 11px;
    color: #5e5e5e;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .timeline .timeline_area .timeline_current_bar {
    opacity: 0.7;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 10;
    width: 1px;
    background-color: #f6f6f6;
  }

  .timeline .timeline_area .timeline_current_bar::before {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 4.5px 0 4.5px;
    border-color: #f6f6f6 transparent transparent transparent;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translateX(-webkit-calc(-50% + 0.5px));
    -moz-transform: translateX(-moz-calc(-50% + 0.5px));
    -ms-transform: translateX(calc(-50% + 0.5px));
    -o-transform: translateX(calc(-50% + 0.5px));
    transform: translateX(calc(-50% + 0.5px));
    content: "";
  }

  .timeline .timeline_area .timeline_caption {
    opacity: 0.8;
    position: absolute;
    top: 35px;
    height: 63px;
    border: 1px solid #2b2b2b;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: #1a1a1a;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    cursor: move;
  }

  .timeline .timeline_area .timeline_caption .resize_handle {
    opacity: 0;
    position: absolute;
    top: -1px;
    bottom: -1px;
    z-index: 90;
    width: 7px;
    height: auto;
    background-color: #0a87ff;
    -webkit-transition: opacity 0.1s;
    -o-transition: opacity 0.1s;
    -moz-transition: opacity 0.1s;
    transition: opacity 0.1s;
  }

  .timeline .timeline_area .timeline_caption .resize_handle::before {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 2px;
    height: 20px;
    background-color: rgba(0, 0, 0, 0.3);
    content: "";
  }

  .timeline .timeline_area .timeline_caption .resize_handle .label {
    opacity: 0;
    position: absolute;
    top: -28px;
    left: 50%;
    padding: 1px 5px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: #0a87ff;
    font-size: 13px;
    -webkit-transition: inherit;
    -o-transition: inherit;
    -moz-transition: inherit;
    transition: inherit;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .timeline .timeline_area .timeline_caption .resize_handle .label::after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 3.5px 0 3.5px;
    border-color: #0a87ff transparent transparent transparent;
    position: absolute;
    bottom: -4px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    content: "";
  }

  .timeline .timeline_area .timeline_caption .resize_handle_left {
    left: -7px;
    -webkit-border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px;
  }

  .timeline .timeline_area .timeline_caption .resize_handle_right {
    right: -7px;
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
  }

  .timeline .timeline_area .timeline_caption .resize_handle_right::before {
    margin-right: 1px;
  }

  .timeline .timeline_area .timeline_caption .resize_handle:hover .label {
    opacity: 1;
  }

  .timeline .timeline_area .timeline_caption .caption_txt {
    display: block;
    overflow: hidden;
    position: absolute;
    top: 5px;
    right: 10px;
    bottom: 5px;
    left: 10px;
    font-size: 17px;
    line-height: 1.5;
    -webkit-transition: -webkit-border-radius 0.1s;
    transition: -webkit-border-radius 0.1s;
    -o-transition: border-radius 0.1s;
    -moz-transition: border-radius 0.1s, -moz-border-radius 0.1s;
    transition: border-radius 0.1s;
    transition: border-radius 0.1s, -webkit-border-radius 0.1s, -moz-border-radius 0.1s;
  }

  .timeline .timeline_area .timeline_caption:hover {
    opacity: 1;
    border-color: #0a87ff;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    color: #fff;
  }

  .timeline .timeline_area .timeline_caption:hover .resize_handle {
    opacity: 1;
  }


`;
