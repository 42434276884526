import React from "react";
import {atom} from "jotai";
import {CaptionItemProps, CaptionListProps} from "../types/caption";


export const captionListState = atom<CaptionListProps[]>([{
  seq:1,
  time_ini: "00:00:00,000",
  time_end: "00:00:05,000",
  caption: ""
}])

export const captionItemState = atom<CaptionItemProps>({
  currentCaptionId:1
})
