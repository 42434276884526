import React from 'react';


/**
 구분자

 * @param data1 설명
 * @return {data1} 반환
 * @throws ValidException 예외 발생
 * @author plaq99@rumpus.co.kr
 * @date 03-20-2023
 */

export default function ControlButtonSeparator() {
  return (
    <>
      <div className="group group_time separator"> / </div>
    </>
  );
}
