import React from 'react';
import {useAtom} from "jotai";
import {initializeState} from "../../../states/initialize";



/**
 번역 구간 알림

 * @param data1 설명
 * @return {data1} 반환
 * @throws ValidException 예외 발생
 * @author plaq99@rumpus.co.kr
 * @date 03-20-2023
 */

export default function HeaderNotice() {

  const [{start_time, end_time}] = useAtom(initializeState);

  return (
    <>
      <div className="notice">
        <i className="bi bi-arrow-bar-right"></i>
        번역 구간 : {start_time} ~ {end_time}
      </div>
    </>
  );
}
