import React, {useEffect, useRef} from 'react';
import ReactPlayer from "react-player";
import {useAtom} from "jotai";
import {initializeState} from "../../../states/initialize";
import {playerState} from "../../../states/player";
import {timestamp2seconds} from "../../../helper/helper";


/**
 플레이어

 * @param data1 설명
 * @return {data1} 반환
 * @throws ValidException 예외 발생
 * @author plaq99@rumpus.co.kr
 * @date 03-20-2023
 */

export default function Player() {

  const reactPlayer = useRef<ReactPlayer>(null);
  const [{video_url}] = useAtom(initializeState);
  const [{playOn, muteOn,intervalOn, intervalStartTime, seekOnTime, currentVolume, currentTime, currentSpeed}, setPlayer] = useAtom(playerState);


  useEffect(() => {

    setTimeout(() => {
      setPlayer((prev) => ({...prev, duration: reactPlayer.current?.getDuration()}))
    }, 300)

  }, []);


  useEffect(() => {
    reactPlayer.current?.seekTo(currentTime);
  }, [seekOnTime]);

  useEffect(() => {

    reactPlayer.current?.seekTo(timestamp2seconds(intervalStartTime));
  }, [intervalOn]);


  return (
    <>
      <ReactPlayer
        ref={reactPlayer}
        url={video_url}
        muted={muteOn}
        playing={playOn}
        playsinline={true}
        volume={currentVolume}
        playbackRate={currentSpeed}
        progressInterval={80}
        /*onSeek = {()=>setPlayer((prev) => ({...prev, seekOnTime: true}))}
        onBufferEnd = {()=>setPlayer((prev) => ({...prev, seekOnTime: false }))}*/


        onProgress={(e) => {
          setPlayer((prev: any)=>({...prev, currentTime: e.playedSeconds}))
        }}
      />
    </>
  );
}
