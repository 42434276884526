import React from 'react';
import styled from "styled-components";
import CaptionInfoInner from "./CaptionInfoInner";
import CaptionInfoTime from "./CaptionInfoTime";
import {CaptionListProps} from "../../../types/caption";

type CaptionInfoProps = {
  captionData:CaptionListProps
}

/**
 자막 정보 레이아웃 (넘버링, 시간수정)

 * @param data1 설명
 * @return {data1} 반환
 * @throws ValidException 예외 발생
 * @author plaq99@rumpus.co.kr
 * @date 03-20-2023
 */

export default function CaptionInfo({captionData}:CaptionInfoProps) {
  return (
    <InfoArea>
      <CaptionInfoInner captionData={captionData} />
      <CaptionInfoTime captionData={captionData}/>
    </InfoArea>
  );
}


const InfoArea = styled.div`
  position: relative;
  z-index: 10;
`;

