import React from 'react';
import styled from "styled-components";

import HeaderTitle from "./Contents/HeaderTitle";
import HeaderNotice from "./Contents/HeaderNotice";
import HeaderAi from "./Contents/HeaderAi";
import HeaderSave from "./Contents/HeaderSave";
import HeaderDownLoad from "./Contents/HeaderDownLoad";
import HeaderRefresh from "./Contents/HeaderRefresh";


/**
 헤더 레이아웃 (제목, 번역구간, AI 팝업, 저장, 다운로드)

 * @param data1 설명
 * @return {data1} 반환
 * @throws ValidException 예외 발생
 * @author plaq99@rumpus.co.kr
 * @date 03-20-2023
 */

export default function HeaderBox() {
  return (
    <HeaderArea>
      <HeaderTitle/>
      <HeaderNotice/>
      <HeaderAi/>
      <HeaderRefresh/>
      <HeaderSave/>
      <HeaderDownLoad/>
    </HeaderArea>
  );
}

const HeaderArea = styled.header`
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: none;
    -moz-box-flex: 0;
    -ms-flex: none;
    flex: none;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 50px;
    padding: 0 20px 0 20px;
    border-bottom: 2px solid #1a1a1a;
    background-color: #2b2b2b;

    .video_name {
        overflow: hidden;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -moz-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        font-size: 17px;

        .inner {
            display: block;
            overflow: hidden;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

    }

    .notice {
        font-size: 15px;
        padding: 5px;
        border: 1px solid #111;
        background-color: #1f1f1f;
        margin: 0 5px;

        .bi {
            cursor: pointer;
        }
    }

    .trans_service {
        margin-left: 10px;
        font-size: 15px;
        padding: 5px;
        border: 1px solid #111;
        background-color: #1f1f1f;
        cursor: pointer;
    }

    .hm_hyperModalWrapper {
        z-index: 10000;
    }

    .select_area {
        width: 120px;
    }

    .btn_ai_translate {
        width: 100px;
        height: 32px;
        margin: 0 5px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        background-color: #111;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        vertical-align: center;
        line-height: 28px;
        cursor: pointer;
    }

    .btn_refresh {
        width: 30px;
        height: 32px;
        margin: 0 5px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        background-color: #111;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        vertical-align: center;
        line-height: 28px;
        cursor: pointer;
    }

    .btn_save {
        width: 85px;
        height: 32px;
        margin-left: 5px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        background-color: #0a87ff;
        font-size: 14px;
        font-weight: bold;

        &.need_save {
            background-color: #DC143C;
        }
    }
`;
