import React from 'react';
import {CaptionListProps} from "../../../types/caption";
import CaptionInfo from "./CaptionInfo";
import CaptionData from "./CaptionData";
import CaptionButton from "./CaptionButton";
import styled from "styled-components";
import {useAtom} from "jotai/index";
import {captionItemState} from "../../../states/caption";


type CaptionItemProps = {
  captionData : CaptionListProps
}


/**
 자막 전체 레이아웃

 * @param data1 설명
 * @return {data1} 반환
 * @throws ValidException 예외 발생
 * @author plaq99@rumpus.co.kr
 * @date 03-20-2023
 */

export default function CaptionItem({captionData}: CaptionItemProps) {

  const [{currentCaptionId}] = useAtom(captionItemState);

  return (
    <ListCaption
      className={"item " + (captionData.seq == currentCaptionId ? "active" : "")}
      id={(null ? "captionActived" : "")}
    >
      <CaptionInfo captionData={captionData} />
      <CaptionData captionData={captionData}/>
      <CaptionButton captionData={captionData}/>
    </ListCaption>
  );
}




const ListCaption = styled.li`
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  padding: 10px;
  background-color: #222;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  + .item {
    border-top: 1px solid rgba(0, 0, 0, 0.3);
  }

  &:hover {
    background-color: #2f2f2f;

    .caption_btn_area {
      visibility: visible;
    }
  }


  &.active {
    background-color: #2f2f2f;

    &:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 3px solid #0a87ff;
      content: "";
    }

    .input_text {
      border: 1px solid #111;
      background-color: #1f1f1f;
    }
  }
}
`;

















