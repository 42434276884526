import React from "react";
import {atom} from "jotai";
import {ModalProps, SaveProps} from "../types/action";




export const saveState = atom<SaveProps>({
  needSaveOn: false
});

export const modalState = atom<ModalProps>({
  modalOn: false,
  modalContentName: 'AiContent'
});





