import React from 'react';
import {useAtom} from "jotai";
import {playerState} from "../../../states/player";


/**
 볼륨, 속도 조절

 * @param data1 설명
 * @return {data1} 반환
 * @throws ValidException 예외 발생
 * @author plaq99@rumpus.co.kr
 * @date 03-20-2023
 */

export default function ControlRightButton() {

  const [{muteOn, currentVolume, currentSpeed, speedLevel}, setPlayer] = useAtom(playerState);

  return (
    <>
      <div className="group group_video_button">

        <div className="wrapper_btn wrapper_btn_volume">
          <button
            type="button"
            className="btn"
            onClick={(e)=>setPlayer((prev)=>({...prev, muteOn:!muteOn}))}
          >
            <i className={"bi " + (muteOn ? "bi-volume-mute-fill" : "bi-volume-up-fill")} ></i>
            <span className="hide">볼륨조절</span>
          </button>
          <div className="layer_volume" >
            <input
              className="tmp_btn_volume"
              type="range"
              min={0}
              max={1}
              step={0.02}
              value={muteOn ? "0" : currentVolume}
              onChange={(e)=>setPlayer((prev)=>({...prev, currentVolume:Number(e.target.valueAsNumber)}))}
            />
          </div>
        </div>
        <div className="wrapper_btn wrapper_btn_play_speed">
          <button type="button" className="btn btn_play_speed">
            <span className="hide">재생속도</span>
            <span id="txt_play_speed">{currentSpeed}x</span>
          </button>
          <div className="layer_speed">
            {
              speedLevel.map((l,i)=>(
                <button
                  type="button"
                  key = {i}
                  className={"btn_set_speed " + (l == currentSpeed ? "active" : "")}
                  value = {l}
                  onClick={(e)=>setPlayer((prev)=>({...prev, currentSpeed:Number(e.currentTarget.value)}))}
                >{l}x</button>
              ))
            }
          </div>
        </div>
      </div>
    </>
  );
}
