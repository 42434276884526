import React from 'react';
import {InitializeProps} from "../../../types/initialize";
import {useAtom} from "jotai";
import {initializeState} from "../../../states/initialize";


/**
 영상 제목

 * @param data1 설명
 * @return {data1} 반환
 * @throws ValidException 예외 발생
 * @author plaq99@rumpus.co.kr
 * @date 03-20-2023
 */

export default function HeaderTitle() {

  const[{video_title}] = useAtom(initializeState);

  return (
    <>
      <h1 className="video_name">
        <div className="inner">{video_title}</div>
      </h1>
    </>
  );
}
