import React, {useRef} from 'react';
import styled from 'styled-components';
import PlayerCaptionInfo from "./Contents/PlayerCaptionInfo";
import PlayerCaptionWindow from "./Contents/PlayerCaptionWindow";
import Player from "./Contents/Player";
import PlayerBezel from "./Contents/PlayerBezel";

/**
 플레이어 레이아웃 (플레이어, 재생 콘트롤러 - 프로세스바, 볼륨조정, 속도 조정, 재생, 멈춤, 5초 전후 이동, 반복, 현재/전체 시간)

 * @param data1 설명
 * @return {data1} 반환
 * @throws ValidException 예외 발생
 * @author plaq99@rumpus.co.kr
 * @date 03-20-2023
 */

export default function PlayerBox() {

  return (

    <PlayerArea>
      <InnerPlayerArea>
        <PlayerCaptionInfo />
        <Player />
        <PlayerBezel />
        <PlayerCaptionWindow />
      </InnerPlayerArea>
    </PlayerArea>

  );
}

const PlayerArea = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  min-height: 56vh;
  background-color: #111;

  .tit_area {
    -webkit-box-flex: 0;
    -webkit-flex: none;
    -moz-box-flex: 0;
    -ms-flex: none;
    flex: none;
    padding: 0 20px;
    font-size: 16px;
    color: #8f8f8f;
    line-height: 50px;
    position: absolute;

    em {
      font-weight: bold;
      color: #f6f6f6;
    }

    .item {
      display: inline-block;
      vertical-align: top;

      + .item {
        margin-left: 30px;
      }
    }
  }


  video {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: block;
    width: 90% !important;
    height: 70% !important;
  }

  .btn_play-pause {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0;
    width: 100px;
    height: 100px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 60px;
    line-height: 1;
    -webkit-transition: opacity 0.1s;
    -o-transition: opacity 0.1s;
    -moz-transition: opacity 0.1s;
    transition: opacity 0.1s;

    .bi {
      margin-left: 5px;
    }

  }

  .video_caption_area {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    right: 20px;
    bottom: 8%;
    left: 20px;

    .caption {
      width: auto;
      max-width: 800px;
      padding: 5px 15px;
      background-color: #000;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      font-size: 16px;
      line-height: 1.5;
      white-space: pre-wrap;
    }

    @media screen and (min-width: 1280px) {
      .caption {
        font-size: 18px;
      }
    }

    @media screen and (min-width: 1440px) {
      .caption {
        font-size: 22px;
      }
    }
  }
`;



const InnerPlayerArea = styled.div`
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
    
  &:hover{
    .btn_play-pause {
      opacity : 1;
    }
  }
`;
