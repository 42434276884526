import React from 'react';
import {useAtom} from "jotai";
import {modalState} from "../../../states/action";


/**
 AI 번역 보기 버튼

 * @param data1 설명
 * @return {data1} 반환
 * @throws ValidException 예외 발생
 * @author plaq99@rumpus.co.kr
 * @date 03-20-2023
 */

export default function HeaderAi() {

  const [{modalOn}, setModalOn] = useAtom(modalState);

  return (
    <>
      <button
        type="button"
        className="btn_ai_translate"
        onClick={() => {
          setModalOn({modalOn:!modalOn, modalContentName:'AiContent'})
          //alert("번역된 자막이 없습니다. AI 번역 상태를 확인해주세요."); /* plaq99 수정 */
        }}
      >
        AI 번역 보기
      </button>
    </>
  );
}
