import React from 'react';
import {playerState} from "../../../states/player";
import {useAtom} from "jotai";
import {second2timestamp} from "../../../helper/helper";


/**
 영상 남은 시간 표시

 * @param data1 설명
 * @return {data1} 반환
 * @throws ValidException 예외 발생
 * @author plaq99@rumpus.co.kr
 * @date 03-20-2023
 */

export default function ControlTimeButton() {

  const [{currentTime}] = useAtom(playerState);
  const currentTimeStamp = second2timestamp(currentTime,"complete_array")[0];

  return (
    <>
      <div className="group group_time">
        <div className="item">HR <em id="play_hours">{currentTimeStamp.hours}</em></div>
        <div className="item">MIN <em id="play_minutes">{currentTimeStamp.minutes}</em></div>
        <div className="item">SEC <em id="play_seconds">{currentTimeStamp.seconds}</em></div>
        <div className="item">FRM <em id="play_frame">{currentTimeStamp.milliseconds}</em></div>
      </div>
    </>
  );
}
