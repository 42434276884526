import React, {useEffect} from "react";
import DefaultCSS from "./resource/DefaultCSS";
import Studio from "./pages/Studio";
import {fetchAxiosGet} from "./helper/axiosPost";
import {initializeState, initializeUrlState} from "./states/initialize";
import {useAtom} from "jotai";
import {captionItemState, captionListState} from "./states/caption";

/**
 App.tsx (기본 CSS 설정, initialize, studio 불러오기)

 * @param data1 설명
 * @return {data1} 반환
 * @throws ValidException 예외 발생
 * @author plaq99@rumpus.co.kr
 * @date 03-20-2023
 */


function SetInitialize() {

  const [{initialTransUrl, initialCaptionUrl}] = useAtom(initializeUrlState);
  const [, setInitialize] = useAtom(initializeState);
  const [, setCaptionList] = useAtom(captionListState);
  const [, setCurrentCaptionItem] = useAtom(captionItemState);

  useEffect(() => {
    // 데이터 받아오기
    fetchAxiosGet(initialTransUrl).then((data) => {
      setInitialize(data.result);
    });

    fetchAxiosGet(initialCaptionUrl).then((data) => {
      setCaptionList(data);
    });

  }, []);

  return (
    <>
    </>
  );

}


export default function App() {
  return (
    <>
      <DefaultCSS />
      <SetInitialize/>
      <Studio />
    </>
  );
}


