import React from 'react';
import styled from "styled-components";
import ProgressBar from "./Contents/ProgressBar";
import ControlLeftButton from "./Contents/ControlLeftButton";
import ControlRightButton from "./Contents/ControlRightButton";
import ControlTimeButton from "./Contents/ControlTimeButton";
import ControlDurationButton from "./Contents/ControlDurationButton";
import ControlButtonSeparator from "./Contents/ControlButtonSeperator";
import Repeat from "./Contents/Repeat";


/**
 플레이어 콘트롤러 (재생바, 재생/중지, 5초 전후이동, 속도, 볼륨, 음소거, 반복, 중지, 현재/전체 시간)

 * @param data1 설명
 * @return {data1} 반환
 * @throws ValidException 예외 발생
 * @author plaq99@rumpus.co.kr
 * @date 03-20-2023
 */

export default function ControllerBox() {
  return (
    <ControllerArea>
      <ProgressBar />
      <ConsoleArea>
        <Repeat />
        <ControlLeftButton />
        <ControlRightButton />
        <ControlTimeButton />
        <ControlButtonSeparator />
        <ControlDurationButton />
      </ConsoleArea>
    </ControllerArea>
  );
}

const ControllerArea = styled.div`
  background-color: #1a1a1a;

  .play_progress {
    position: relative;
    display: flex;
    flex-grow:1;
    cursor: pointer;
  }
`;
const ConsoleArea = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 24px 14px;

  .group_video_button {

    .layer_tooltip {
      display: none;
      position: absolute;
      top: -20px;
      left: 50%;
      z-index: 100;
      padding: 0 5px;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      background-color: #000;
      font-size: 11px;
      color: #fff;
      line-height: 20px;
      white-space: nowrap;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      transform: translateX(-50%);

      &::after {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 5px 0 5px;
        border-color: #000 transparent transparent transparent;
        display: inline-block;
        position: absolute;
        bottom: -8px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
        content: "";
      }
    }


    .btn {
      position: relative;
      width: 40px;
      height: 40px;
      font-size: 24px;
      line-height: 1;
      -webkit-transition: color 0.1s;
      -o-transition: color 0.1s;
      -moz-transition: color 0.1s;
      transition: color 0.1s;

      &:hover {
        color: #0a87ff;

        .layer_tooltip {
          display: block;
        }

      }

    }

    .wrapper_btn {
      float: left;
      position: relative;
    }

    .wrapper_btn_volume {
      &:hover {
        .layer_volume {
          display: block;
        }
      }
    }

    .tmp_btn_volume {
      writing-mode: bt-lr; /* IE */
      -webkit-appearance: slider-vertical; /* WebKit */
      width: 11px;
      height: 110px;
      background: #fff;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      &::-webkit-slider-thumb {
        /*visibility:hidden;*/
      }
    }

    .layer_volume {
      display: none;
      position: absolute;
      bottom: 55px;
      left: 50%;
      z-index: 100;
      width: 25px;
      height: 123px;
      padding: 5px;
      background-color: #3c3c3c;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      transform: translateX(-50%);

      @media screen and (max-width: 1280px) {
        bottom: 40px;
      }

      @media screen and (min-width: 1280px) {
        bottom: 45px;
      }

      @media screen and (min-width: 1440px) {
        bottom: 55px;
      }

    }

    .wrapper_btn_play_speed {
      margin-left: 10px;

      &:hover {
        .layer_speed {
          display: block;
        }
      }

    }

    .btn_play_speed {
      font-size: 24px;
      font-weight: bold;
    }

    .layer_speed {
      display: none;
      position: absolute;
      bottom: 55px;
      left: 50%;
      z-index: 100;
      width: 50px;
      background-color: #3c3c3c;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      transform: translateX(-50%);

      @media screen and (max-width: 1280px) {
        bottom: 40px;
      }

      @media screen and (min-width: 1280px) {
        bottom: 45px;
      }

      @media screen and (min-width: 1440px) {
        bottom: 55px;
      }


      .btn_set_speed {
        padding: 0 10px;
        font-size: 12px;
        color: #8f8f8f;
        line-height: 30px;

        &.active {
          font-weight: bold;
          color: #fff;
        }

        + .btn_set_speed {
          border-top: 1px solid #444;
        }

      }


    }


  }

  .group_time {
    position: relative;

    &.separator {
      align-self: flex-end
    }

    .item {
      display: inline-block;
      position: relative;
      font-size: 14px;
      font-weight: bold;
      color: #5d5d5d;
      text-align: center;
      vertical-align: top;

      em {
        display: block;
        margin-top: 8px;
        font-size: 16px;
        color: #fff;
      }

      + .item {
        margin-left: 10px;

        &:before {
          position: absolute;
          bottom: 0;
          left: -10px;
          color: #fff;
          content: ":";
        }
      }

    }

  }

  .group_repeat {
    font-size: 18px;
    line-height: 30px;

    .bi {
      margin-right: 5px;

      &.bi-repeat {
        &.active {
          color: #0a87ff
        }
      }

    }

    .input_text {
      width: 115px;
      height: 30px;
      background-color: #111;
      font-size: 15px;
      text-align: center;
    }

    .btn {
      width: auto;
      height: 30px;
      margin-left: 3px;
      padding: 0 6px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      background-color: #2b2b2f;
      font-size: 13px;
      -webkit-transition: color 0.1s;
      -o-transition: color 0.1s;
      -moz-transition: color 0.1s;
      transition: color 0.1s;

      &:hover {
        font-weight: bold;
        color: #0a87ff;
      }

      &.active {
        font-weight: bold;
        color: #0a87ff;
      }

      &.btn_stop {
        font-weight: bold;
        color: #DC143C;
      }

      &.btn_reset {
        &:hover {
          font-weight: bold;
          color: #DC143C;
        }
      }
    }

  }


  @media screen and (min-width: 1280px) {

    padding: 30px 20px;

    .group_video_button {
      .btn {
        width: 50px;
        height: 50px;
        font-size: 30px;
      }
    }

    .group_time {

      .item {
        font-size: 15px;

        em {
          margin-top: 10px;
          font-size: 18px;
        }

        + .item {
          margin-left: 20px;

          &:before {
            left: -13px;
          }
        }
      }

    }

    .group_repeat {
      font-size: 18px;
      line-height: 36px;

      .bi {
        margin-right: 10px;
      }

      .input_text {
        width: 120px;
        height: 36px;
      }

      .btn {
        height: 36px;
        padding: 0 10px;
        font-size: 15px;
      }

    }

  }

  @media screen and (min-width: 1440px) {

    padding: 34px 24px;

    .group_video_button {
      .btn {
        width: 60px;
        height: 60px;
        font-size: 34px;
      }
    }

    .group_time {
      .item {
        font-size: 16px;

        em {
          margin-top: 15px;
          font-size: 22px;
        }

        + .item {
          margin-left: 30px;

          &:before {
            left: -18px;
          }
        }

      }
    }

    .group_repeat {
      font-size: 18px;

      .bi {
        margin-right: 10px;
      }

      .input_text {
        width: 120px;
        height: 36px;
      }

      .btn {
        height: 36px;
        padding: 0 10px;
        font-size: 15px;
      }

    }

  }
  
`;
