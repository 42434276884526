import React from 'react';
import ReactDOM from 'react-dom/client';

import App from "./App";

/**
 index.tsx 인덱스 시작

 * @param data1 설명
 * @return {data1} 반환
 * @throws ValidException 예외 발생
 * @author plaq99@rumpus.co.kr
 * @date 03-20-2023
 */


const root = document.getElementById('wrapper') as HTMLElement;

ReactDOM.createRoot(root).render(
  <App/>
);


