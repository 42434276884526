import React from 'react';
import {useAtom} from "jotai";
import {captionListState} from "../../../states/caption";
import {initializeState} from "../../../states/initialize";


/**
 자막 저장

 * @param data1 설명
 * @return {data1} 반환
 * @throws ValidException 예외 발생
 * @author plaq99@rumpus.co.kr
 * @date 03-20-2023
 */

function saveFile() {

  const [{job_id}] = useAtom(initializeState);
  const [caption] = useAtom(captionListState);

  if (job_id != "") {

    if (window.confirm("자막을 저장하시겠습니까?")) {
      //export2txt()
    }

  } else {
    alert("자막 data가 없습니다. job_id가 있는지 확인해주세요.");
  }

}


export default function HeaderSave() {
  return (
    <>
      <button
        type="button"
        className={"btn_save " + (null ? "" : "need_save")}
        onClick={()=>saveFile()} /* plaq99 수정 */
      >
        {null ? "SAVE" : "NEED SAVE"} {/* plaq99 수정 */}
      </button>
    </>
  );
}
