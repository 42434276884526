import React from 'react';
import {useAtom} from "jotai/index";
import {playerState} from "../../../states/player";
import {second2timestamp} from "../../../helper/helper";


/**
 콘트롤러 반복 재생

 * @param data1 설명
 * @return {data1} 반환
 * @throws ValidException 예외 발생
 * @author plaq99@rumpus.co.kr
 * @date 03-20-2023
 */

export default function Repeat() {

  const [{intervalOn, intervalStartTime, intervalEndTime, currentTime}, setPlayer] = useAtom(playerState);

  return (
    <>
      <div className="group group_repeat">
        <i className={"bi bi-repeat "+(null ? "active" : "")}></i>
        <span className="hide">구간반복설정</span>

        <input
          type="text"
          className="input_text"
          name="repeat_time_start"
          id="repeat_time_start"
          value={intervalStartTime}
          readOnly
          onBlur = {(e)=>setPlayer((prev)=>({...prev,intervalStartTime: e.currentTarget.value}))}
        />
        <label htmlFor="repeat_time_start" className="input_label hide">구간반복 시작시간</label>
        <span>~</span>
        <input
          type="text"
          className="input_text"
          name="repeat_time_end"
          id="repeat_time_end"
          value={intervalEndTime}
          readOnly
          onBlur = {(e)=>setPlayer((prev)=>({...prev,intervalEndTime: e.currentTarget.value}))}
        />
        <label htmlFor="repeat_time_end" className="input_label hide">구간반복 종료시간</label>
        <button
          type="button"
          className="btn"
          name="intervalSubmit"
          value={second2timestamp(currentTime,"complete" ) }
          onClick={(e)=>{
            setPlayer((prev)=>({...prev,intervalStartTime: e.currentTarget.value}))
          }}
        > 시작</button>
        <button
          type="button"
          className="btn"
          name="intervalSubmit"
          value={second2timestamp(currentTime,"complete" ) }
          onClick={(e)=>setPlayer((prev)=>({...prev,intervalEndTime: e.currentTarget.value}))}
        > 종료</button>

        <button
          type="button"
          className={"btn " + (null ? "btn_stop" : "") }
          name="intervalSubmit"
          onClick={(e)=>setPlayer((prev)=>({...prev,intervalOn:true}))}
        > {(intervalOn ? "중지" : "반복")}</button>

        <button
          type="button"
          className="btn btn_reset"
          name="intervalReset"
          onClick={(e)=>setPlayer((prev)=>({...prev,intervalOn:false}))}
        >리셋</button>
      </div>

    </>
  );
}
