import React from 'react';
import {useAtom} from "jotai";
import {captionItemState, captionListState} from "../../../states/caption";


/**
 플레이어 내 자막 (하단 배치)

 * @param data1 설명
 * @return {data1} 반환
 * @throws ValidException 예외 발생
 * @author plaq99@rumpus.co.kr
 * @date 03-20-2023
 */

export default function PlayerCaptionWindow() {

  const [captionList]       = useAtom(captionListState);
  const [{currentCaptionId}] = useAtom(captionItemState);

  return (
    <>
      {
        captionList &&
        <div className="video_caption_area type_align_left">
          <div className="caption">{captionList[currentCaptionId-1].caption}</div>
        </div>
      }
    </>
  );
}
