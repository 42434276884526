import React from "react";
import {atom} from "jotai";
import {PlayerProps} from "../types/player";

export const playerState = atom<PlayerProps>({
  playOn: false,
  muteOn: false,
  seekOnTime: 0,
  intervalOn: false,
  intervalStartTime: "00:00:00,000",
  intervalEndTime: "00:00:05,000",
  currentVolume: 1,
  currentSpeed: 1,
  currentTime: 0,
  duration: 0,
  startTime: "",
  endTime: "",
  speedLevel: [2.0, 1.5, 1.25, 1.0, 0.5]
});
