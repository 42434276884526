import React from 'react';
import styled from "styled-components";
import {CaptionListProps} from "../../../types/caption";

type CaptionInfoTimeProps = {
  captionData: CaptionListProps
}

/**
 자막 시작/종료 시간

 * @param data1 설명
 * @return {data1} 반환
 * @throws ValidException 예외 발생
 * @author plaq99@rumpus.co.kr
 * @date 03-20-2023
 */

export default function CaptionInfoTime({captionData}:CaptionInfoTimeProps) {
  return (
    <>
      <TimeArea>
        <label htmlFor="caption_time_start" className="hide">자막 시작 시간</label>
        <input
          type="text"
          className="input_text"
          id="caption_time_start"
          name="time_start"
          defaultValue={captionData.time_ini}
          onClick={() => {}}
        />
        <label htmlFor="caption_time_end" className="hide">자막 종료 시간</label>
        <input
          type="text"
          className="input_text"
          id="caption_time_end"
          name="time_end"
          defaultValue={captionData.time_end}
          onClick={() => {}}
        />
      </TimeArea>
    </>
  );
}

const TimeArea = styled.div`
  width: 100px;
  padding-top: 2px;

  .input_text {
    height: 26px;
    padding: 0;
    font-size: 14px;
    text-align: center;
    -webkit-transition: -webkit-transform 0.1s;
    transition: -webkit-transform 0.1s;
    -o-transition: -o-transform 0.1s;
    -moz-transition: transform 0.1s, -moz-transform 0.1s;
    transition: transform 0.1s;
    transition: transform 0.1s, -webkit-transform 0.1s, -moz-transform 0.1s, -o-transform 0.1s;
    -webkit-transform: translateY(1px);
    -moz-transform: translateY(1px);
    -ms-transform: translateY(1px);
    -o-transform: translateY(1px);
    transform: translateY(1px);


    ~ .input_text {
      -webkit-transform: translateY(-1px);
      -moz-transform: translateY(-1px);
      -ms-transform: translateY(-1px);
      -o-transform: translateY(-1px);
      transform: translateY(-1px);
    }
  }


`;
