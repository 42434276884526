import React from 'react';
import styled from "styled-components";
import {CaptionListProps} from "../../../types/caption";

type CaptionInfoInnerProps = {
  captionData: CaptionListProps
}

/**
 자막 정보 (넘버링)

 * @param data1 설명
 * @return {data1} 반환
 * @throws ValidException 예외 발생
 * @author plaq99@rumpus.co.kr
 * @date 03-20-2023
 */

export default function CaptionInfoInner({captionData}: CaptionInfoInnerProps) {
  return (
    <InnerArea>
      <div className="number_area">{captionData.seq}</div>
      <div className="video_btn_area">
        <button
          type="button"
          className="btn"
          title="이 자막에 해당하는 부분 재생"
          onClick={() => {
          }}
        >
          <i className={"bi " + (null ? "bi-pause-fill" : "bi-play-fill")}></i>
          <span className="hide">이 자막에 해당하는 부분 재생</span>
        </button>

        <button
          type="button"
          className={"btn btn-repeat " + (null ? "active" : "")}
          title="이 자막에 해당하는 부분 반복"
          onClick={() => {
          }}
        >
          <i className="bi bi-repeat"></i>
          <span className="hide">이 자막에 해당하는 부분 반복</span>
        </button>
      </div>
    </InnerArea>
  );
}


const InnerArea = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;


  .number_area {
    font-size: 25px;
    font-weight: bold;
    line-height: 1.5;
  }

  .video_btn_area {
    .btn {
      width: 24px;
      height: 24px;
      margin-left: 10px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      font-size: 20px;
      line-height: 1;
      -webkit-transition: background-color 0.1s;
      -o-transition: background-color 0.1s;
      -moz-transition: background-color 0.1s;
      transition: background-color 0.1s;
      opacity: 0.5;

      .bi {
        margin-left: 2px;
      }

      &.active {
        color: #0a87ff;
        opacity: 1.0;
      }

      &:hover {
        color: #0a87ff;
        opacity: 1.0;
      }

      &.btn-repeat {
        margin-left: 0px;
        font-size: 16px;
      }
    }
  }
`;
