import React from 'react';
import Slider from "rc-slider";
import 'rc-slider/assets/index.css';
import {playerState} from "../../../states/player";
import {useAtom} from "jotai";
import {percent2Seconds, seconds2Percent} from "../../../helper/helper";


/**
 플레이어 재생바

 * @param data1 설명
 * @return {data1} 반환
 * @throws ValidException 예외 발생
 * @author plaq99@rumpus.co.kr
 * @date 03-20-2023
 */

export default function ProgressBar() {

  const [{currentTime, duration}, setPlayer] = useAtom(playerState);
  let anchor = seconds2Percent(currentTime, duration);

  return (
    <>
      <div
        className="play_progress"
        style={{touchAction:"none"}}
      >
        <Slider
          defaultValue={0}
          value={anchor}
          draggableTrack={true}
          disabled = {false}
          step={0.05}
          onChange={(e) => {
            setPlayer((prev) => ({...prev, seekOnTime:percent2Seconds(e, duration), currentTime: percent2Seconds(e, duration)}))
          }}
          handleStyle={[{
            backgroundColor: "#fff",
            cursor: "pointer",
            opacity: `1`,
            border: "solid",
            borderColor: "#fff",
            borderWidth: "2px",
            marginTop: "-3px"
          }]}
          trackStyle={{backgroundColor: "#0a87ff", height: "8px"}}
          railStyle={{backgroundColor: "#454545", height: "8px"}}
        />
      </div>
    </>
  );
}
