import React from "react";
import {atom} from "jotai";
import {InitializeProps, InitializeUrlProps} from "../types/initialize";

const params = new URLSearchParams(location.search);
let getParamsJobId = params.get("job_id") ? params.get("job_id") : "";

//http://211.110.178.253:4000/api/vcast/translate/getTransData?job_id=TLI67988101
//http://211.110.178.253:4000/api/vcast/translate/getCaptionData?job_id=TLI67988101
//http://211.110.178.253:4000/api/vcast/translate/getSupportedTransData
//http://211.110.178.253:4000/api/vcast/translate/setTranslateJob?job_id=TLI67988101&srt_url=
//http://211.110.178.253:4000/api/storage/upload/uploadCaptionData

export const initializeUrlState = atom<InitializeUrlProps>({
  initialTransUrl: `${process.env.REACT_APP_VCAST_HOST}/api/vcast/translate/getTransData?job_id=${getParamsJobId}`,
  initialCaptionUrl: `${process.env.REACT_APP_VCAST_HOST}/api/vcast/translate/getCaptionData?job_id=${getParamsJobId}`
});


export const initializeState = atom<InitializeProps>({
  job_id: "",
  user_id: "",
  video_url: "",
  start_time: "00:00:00",
  end_time: "00:00:00",
  srt_url: "",
  video_title: "",
  video_language: "",
  supported_trans_service: [{
    trans_srt_id: "",
    trans_port: "",
    trans_path: "",
    trans_service: "",
    trans_url: ""
  }]
});


