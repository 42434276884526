import React from 'react';


/**
 새로 고침

 * @param data1 설명
 * @return {data1} 반환
 * @throws ValidException 예외 발생
 * @author plaq99@rumpus.co.kr
 * @date 03-20-2023
 */

export default function HeaderRefresh() {
  return (
    <>
      <button type="button" className="btn_refresh">
        <i
          className="bi bi-arrow-clockwise"
          onClick={() => window.location.reload()}
        ></i>
      </button>
    </>
  );
}
