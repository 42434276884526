import {CaptionListProps} from "../types/caption";

function digitalClock(num: number, numberOfZeroes: number = 2): string {
  let i = 1
  while (i <= numberOfZeroes) {
    const tens = Math.pow(10, i)

    if (num >= tens) i++
    else break
  }

  return '0'.repeat(numberOfZeroes - i) + String(num)
}

export function second2timestamp(total_seconds: number, mode: 'minute' | 'hours' | 'complete' | 'complete_array'): any {

  const milliseconds = Math.floor((total_seconds % 1) * 1000),
    seconds = Math.floor(total_seconds % 60),
    minutes = Math.floor((total_seconds % 3600) / 60),
    hours = Math.floor(total_seconds / 3600)

  if (mode === 'minute')
    return `${digitalClock(minutes)}:${digitalClock(seconds)}`
  else if (mode === "hours")
    return `${digitalClock(hours)}:${digitalClock(minutes)}:${digitalClock(seconds)}`
  else if (mode === 'complete')
    return `${digitalClock(hours)}:${digitalClock(minutes)}:${digitalClock(seconds)},${digitalClock(milliseconds, 3)}`
  else if (mode == 'complete_array')
    return [{"hours":('00' + hours).slice(-2), "minutes":('00' + minutes).slice(-2), "seconds":('00' + seconds).slice(-2), "milliseconds":('000' + milliseconds).slice(-3)}];

  else
    return ''
}

export function timestamp2seconds(timestamp: string): number {

  const hours = Number(timestamp.slice(0, 2)),
    minutes = Number(timestamp.slice(3, 5)),
    seconds = Number(timestamp.slice(6, 8)),
    milliseconds = Number(timestamp.slice(9))

  return (hours * 60 * 60) + (minutes * 60) + (seconds) + (milliseconds / 1000)
}



export function seconds2Percent (seconds:number, duration:number ) : number {

  let handlerPercent = (seconds / duration) * 100;

  return handlerPercent;

}


export function percent2Seconds (percent:number|number[], duration:number ) : number {

  let aa = Number(percent);
  let seekTime = duration * (aa/100);

  return seekTime;

}


export function export2srt(captions: CaptionListProps[]): string {
  return captions.map((c, i) =>
    `${i + 1}\n${c.time_ini} --> ${c.time_end}\n${c.caption}`)
    .join('\n\n')
}


