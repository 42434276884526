import React from 'react';
import styled from "styled-components";
import {CaptionListProps} from "../../../types/caption";

type CaptionButtonProps = {
  captionData : CaptionListProps
}

/**
 자막 추가/삭제 버튼

 * @param data1 설명
 * @return {data1} 반환
 * @throws ValidException 예외 발생
 * @author plaq99@rumpus.co.kr
 * @date 03-20-2023
 */

export default function CaptionButton({captionData}: CaptionButtonProps) {
  return (
    <BtnArea>
      <button
        type="button"
        className="btn btn_remove"
        title="자막 삭제"
        onClick={() => {
        }}
      >
        <i className="bi bi-x-circle-fill"></i>
        <span className="hide">자막 삭제</span>
      </button>
      <button
        type="button"
        className="btn btn_add"
        title="자막 추가"
        onClick={() => {
        }}
      >
        <i className="bi bi-plus-circle-fill"></i>
        <span className="hide">자막 추가</span>
      </button>
    </BtnArea>
  );
}

const BtnArea = styled.div`
  visibility: hidden;
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -moz-box-flex: 0;
  -ms-flex: none;
  flex: none;
  position: relative;
  width: 40px;
  padding-top: 40px;

  .btn {
    font-size: 20px;
  }

  + .btn {
    margin-top: 5px;
  }
`;
