import React from 'react';
import {playerState} from "../../../states/player";
import {useAtom} from "jotai";


/**
 재생, 정지, 5초 전후 버튼

 * @param data1 설명
 * @return {data1} 반환
 * @throws ValidException 예외 발생
 * @author plaq99@rumpus.co.kr
 * @date 03-20-2023
 */

export default function ControlLeftButton() {

  const [{playOn, muteOn, currentTime}, setPlayer] = useAtom(playerState);


  return (
    <>
      <div className="group group_video_button">
        <div className="wrapper_btn">
          <button
            type="button"
            className="btn btn_play-pause"
            onClick={()=>setPlayer((prev)=>({...prev, playOn:!playOn}))}
          >
            <i className={"bi " + (playOn ? "bi-pause-fill" : "bi-play-fill")}></i>
            <div className="layer_tooltip">{playOn ? "일시정지" : "재생"}</div>

          </button>
        </div>
        <div className="wrapper_btn">
          <button
            type="button"
            className="btn"
            onClick={()=>setPlayer((prev)=>({...prev, seekOnTime:currentTime, currentTime:0}))}
          >
            <i className="bi bi-skip-start-fill"></i>
            <div className="layer_tooltip">시작으로 돌아기기</div>
          </button>
        </div>
        <div className="wrapper_btn">
          <button
            type="button"
            className="btn"
            onClick={()=>setPlayer((prev)=>({...prev, seekOnTime:currentTime-5, currentTime:currentTime-5}))}
          >
            <i className="bi bi-rewind-fill"></i>
            <div className="layer_tooltip">5초 이전</div>
          </button>
        </div>
        <div className="wrapper_btn">
          <button
            type="button"
            className="btn"
            onClick={()=>setPlayer((prev)=>({...prev, seekOnTime:currentTime+5, currentTime:currentTime+5}))}

          >
            <i className="bi bi-fast-forward-fill"></i>
            <div className="layer_tooltip">5초 뒤로</div>
          </button>
        </div>
      </div>
    </>
  );
}
