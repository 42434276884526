import React from 'react';
import styled from "styled-components";
import {useAtom} from "jotai";

import {modalState} from "../states/action";

import TimeLineBox from "../components/TimeLine/TimeLineBox";
import HeaderBox from "../components/Header/HeaderBox";
import PlayerBox from "../components/Player/PlayerBox";
import CaptionBox from "../components/Caption/CaptionBox";
import ModalBox from "../components/Modal/ModalBox";
import ControllerBox from "../components/Controller/ControllerBox";


/**
 스튜디오 레이아웃 (헤더, 플레이어, 자막리스트, 타임라인, 팝업)

 * @param data1 설명
 * @return {data1} 반환
 * @throws ValidException 예외 발생
 * @author plaq99@rumpus.co.kr
 * @date 03-20-2023
 */

export default function Studio() {

  const [{modalOn, modalContentName}] = useAtom(modalState);

  return (
    <StudioArea>
      <InnerArea>
        <HeaderBox /> {/*헤더 영역*/}
        <EditorArea>
          <PlayerBox /> {/*플레이어 영역*/}
          <CaptionBox /> {/*자막 영역*/}
        </EditorArea>
        <ControllerBox /> {/*콘트롤러 영역*/}
        <TimeLineBox /> {/*타임라인 영역*/}
        {modalOn && <ModalBox contentName={modalContentName} />} {/*팝업 영역*/}
      </InnerArea>
      <SNBArea /> {/*유틸 영역*/}
    </StudioArea>
  );
}


const StudioArea = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  max-height: -webkit-calc(100% - 50px);
  max-height: -moz-calc(100% - 50px);
  max-height: calc(100% - 50px);
`;

const InnerArea = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  /*height: 100%;*/
  min-height: 500px;
`;

const SNBArea = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  z-index: 100;
  width: 50px;
  padding: 10px 0;
  border-left: 2px solid #1a1a1a;
  background-color: #2b2b2b;
`;

const EditorArea = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 100%;
`;
