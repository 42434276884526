import React from 'react';
import {useAtom} from "jotai";
import {initializeState} from "../../../states/initialize";
import {playerState} from "../../../states/player";


/**
 플레이어 재생/멈춤 버튼 (가운데 버튼)

 * @param data1 설명
 * @return {data1} 반환
 * @throws ValidException 예외 발생
 * @author plaq99@rumpus.co.kr
 * @date 03-20-2023
 */

export default function PlayerBezel() {

  const [{playOn, muteOn}, setPlayer] = useAtom(playerState);

  return (
    <>
      <button
        type="button"
        className="btn_play-pause"
        onClick={()=> {
          setPlayer((prev)=> ({...prev, playOn:!playOn}))
        }} >
        <i className={"bi " + (playOn ? "bi-pause-fill" : "bi-play-fill")}></i>
      </button>
    </>
  );
}
