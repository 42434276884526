import React from 'react';
import {timestamp2seconds} from "../../../helper/helper";
import {useAtom} from "jotai";
import {captionItemState, captionListState} from "../../../states/caption";


/**
 플레이어 내 자막 정보 (순번, 해당 자막의 시간)

 * @param data1 설명
 * @return {data1} 반환
 * @throws ValidException 예외 발생
 * @author plaq99@rumpus.co.kr
 * @date 03-20-2023
 */

export default function PlayerCaptionInfo() {

  const [captionList]       = useAtom(captionListState);
  const [{currentCaptionId}] = useAtom(captionItemState);

  return (
    <>
      {
        captionList &&
        <div className="tit_area">
          <div className="item">No. <em>{captionList[currentCaptionId-1].seq}</em></div>
          <div className="item">Caption Duration <em>{(timestamp2seconds(captionList[currentCaptionId-1].time_end) - timestamp2seconds(captionList[currentCaptionId-1].time_ini)).toFixed(2)} (초)</em></div>
        </div>
      }
    </>
  );
}
