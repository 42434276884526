import React from 'react';
import {useAtom} from "jotai";
import {playerState} from "../../../states/player";
import {second2timestamp} from "../../../helper/helper";


/**
 영상 전체 시간 표시

 * @param data1 설명
 * @return {data1} 반환
 * @throws ValidException 예외 발생
 * @author plaq99@rumpus.co.kr
 * @date 03-20-2023
 */

export default function ControlDurationButton() {

  const [{duration}] = useAtom(playerState);
  const durationTimeStamp = second2timestamp(duration,"complete_array")[0];

  return (
    <>
      <div className="group group_time">
        <div className="item">
          DURATION
          <em id="play_hours">{durationTimeStamp.hours} : {durationTimeStamp.minutes} : {durationTimeStamp.seconds}, {durationTimeStamp.milliseconds}</em>
        </div>
      </div>
    </>
  );
}
