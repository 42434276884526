import {createGlobalStyle} from "styled-components";

const DefaultCSS = createGlobalStyle`
    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
        margin: 0;
        padding: 0;
        -ms-user-select: none; 
        -moz-user-select: -moz-none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        user-select: none;
    }
    
    body {
        font-family: Malgun Gothic, 맑은 고딕, -apple-system, BlinkMacSystemFont, Helvetica, Apple SD Gothic Neo, sans-serif;
        font-size: 16px;
        line-height: 20px;
        -webkit-text-size-adjust: none;
    }
    
    ol,
    ul {
        list-style: none;
    }
    
    img {
        display: inline-block;
        vertical-align: top;
    }
    
    em {
        font-style: normal;
    }
    
    button {
        width: 100%;
        padding: 0;
        border: 0;
        background: none;
        font-family: inherit;
        vertical-align: top;

    }
    
    button,
    [role="button"] {
        cursor: pointer;
    }
    
    button,
    [role="button"],
    select,
    input,
    textarea {
        color: inherit;
        outline: none;

    }
    
    input {
        padding: 0;
    }
    
    input,
    textarea {
        font-family: inherit;
    }
    
    textarea {
        resize: none;
    }
    
    .hide {
        overflow: hidden;
        position: absolute;
        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
        clip: rect(0, 0, 0, 0);
    }
    
    .sp {
        display: inline-block;
        background-image: url(../images/sp_app_9410960b.png);
        -moz-background-size: 2px 2px;
             background-size: 2px 2px;
        background-repeat: no-repeat;
        vertical-align: top;
    }
    
    .bi {
        display: inline-block;
        vertical-align: top;
        font-size: inherit;
        color: inherit;
        line-height: inherit;
        -webkit-transition: inherit;
        -o-transition: inherit;
        -moz-transition: inherit;
        transition: inherit;
    }
    
    .bi::before {
        line-height: inherit;
        vertical-align: top;
    }
    
    html,
    body,
    #wrapper {
        overflow: hidden;
        height: 100%;
    }
    
    body {
        min-width: 1024px;
        min-height: 600px;
        background-color: #1a1a1a;
        color: #e5e5e5;
        letter-spacing: -0.3px;
    }
    
    .input_text {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        height: 32px;
        padding-right: 12px;
        padding-left: 12px;
        border: 0;
        -webkit-border-radius: 4px;
           -moz-border-radius: 4px;
                border-radius: 4px;
        background-color: #1f1f1f;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5;
        outline: 0;
        -webkit-transition: border-color 0.1s, background-color 0.1s;
        -o-transition: border-color 0.1s, background-color 0.1s;
        -moz-transition: border-color 0.1s, background-color 0.1s;
        transition: border-color 0.1s, background-color 0.1s;
        -webkit-appearance: none;
    }
    
    .input_text.type_select {
        padding-right: 32px;
        background-repeat: no-repeat;
        background-position: -webkit-calc(100% - 16px + 7px) 50%;
        background-position: -moz-calc(100% - 16px + 7px) 50%;
        background-position: calc(100% - 16px + 7px) 50%;
        -moz-background-size: 14px 14px;
             background-size: 14px 14px;
    }
    
    .input_text.type_select {
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI2LjQuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxNiAxNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTYgMTY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPHBhdGggY2xhc3M9InN0MCIgZD0iTTEuNiw0LjZjMC4yLTAuMiwwLjUtMC4yLDAuNywwYzAsMCwwLDAsMCwwTDgsMTAuM2w1LjYtNS42YzAuMi0wLjIsMC41LTAuMiwwLjcsMHMwLjIsMC41LDAsMC43bC02LDYKCWMtMC4yLDAuMi0wLjUsMC4yLTAuNywwYzAsMCwwLDAsMCwwbC02LTZDMS41LDUuMiwxLjUsNC44LDEuNiw0LjZDMS42LDQuNiwxLjYsNC42LDEuNiw0LjZ6Ii8+Cjwvc3ZnPgo=");
    }
    
    .ui-widget.ui-widget-content {
        border: 0;
    }
    
    .ui-widget-content {
        background: #2b2b2b;
    }
    
    .ui-corner-all {
        -webkit-border-radius: 0;
           -moz-border-radius: 0;
                border-radius: 0;
    }
    
    .ui-slider-range {
        background: #0a87ff;
    }
    
    .ui-slider-handle {
        display: none;
    }
    
    .ui-slider-horizontal {
        height: 100%;
    }
    
    .ui-slider-vertical {
        width: 100%;
        height: 113px;
    }
    
    .type_bold {
        font-weight: bold;
    }
    
    .type_italic {
        font-style: italic;
    }
    
    .type_underline {
        text-decoration: underline;
    }
    
    .type_align_left {
        text-align: left;
    }
    
    .type_align_center {
        text-align: center;
    }
    
    .type_align_right {
        text-align: right;
    }
    .rc-slider-rail{
      border-radius: 0px;
    }
    .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
      border-color: #fff!important;
      box-shadow: none!important;
    }

    .rc-slider-handle:focus-visible {
      box-shadow: none!important;
    }

    .rc-slider-disabled {
      background-color: transparent!important;
    }
    
`;

export default DefaultCSS;
