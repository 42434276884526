import React from 'react';
import {useAtom} from "jotai/index";
import fileDownload from "js-file-download"
import {captionListState} from "../../../states/caption";
import {export2srt} from "../../../helper/helper";
import {CaptionListProps} from "../../../types/caption";


/**
 SRT 다운로드

 * @param data1 설명
 * @return {data1} 반환
 * @throws ValidException 예외 발생
 * @author plaq99@rumpus.co.kr
 * @date 03-20-2023
 */


function downloadSRTFile() {
  const [caption] = useAtom(captionListState);

  return fileDownload(export2srt(caption), 'caption.srt');
}

export default function HeaderDownLoad() {

  return (
    <>
      <button
        type="button"
        className="btn_save"
        onClick={()=>downloadSRTFile()}
      >
        Download
      </button>
    </>
  );
}
