import React from 'react';
import axios from "axios";
import qs from 'qs';

// 데이터 가져오기 (공용함수)
export async function fetchAxiosGet(url: string) {

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }


}

// 데이터 입력하기 (공용함수)
export async function setAxiosPost(url: string, data: string) {
  const config = {
    method: 'post',
    url: url,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  };

  try {
    const response = await axios.request(config);
    return response.data;
  }catch (error){
    console.log(error);
    return error;
  }







}

export function setExport2txt() {

  const url = '';
  const data = qs.stringify({
    '': ''
  });

  const result = setAxiosPost(url, data);
  return result;

}


