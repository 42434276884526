import React from 'react';
import styled from "styled-components";
import {captionListState} from "../../states/caption";
import {useAtom} from "jotai";
import CaptionItem from "./Contents/CaptionItem";


/**
 자막 레이아웃 (자막 리스트)

 * @param data1 설명
 * @return {data1} 반환
 * @throws ValidException 예외 발생
 * @author plaq99@rumpus.co.kr
 * @date 03-20-2023
 */

export default function CaptionBox() {

  const [captions] = useAtom(captionListState);

  return (
    <>
      <CaptionArea>
        <ListArea>
          <ListCaption>
            {
              captions.map((c)=>(
                <CaptionItem key={c.seq} captionData={c} />
              ))
            }
          </ListCaption>
        </ListArea>
      </CaptionArea>
    </>
  );
}

const CaptionArea = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 450px;
  background-color: #222;

  .title_area {
    -webkit-box-flex: 0;
    -webkit-flex: none;
    -moz-box-flex: 0;
    -ms-flex: none;
    flex: none;
    position: relative;
    padding: 0 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    background-color: #2b2b2b;
    font-size: 16px;
    font-weight: bold;
    line-height: 50px;

    .btn_area {
      position: absolute;
      top: 50%;
      right: 10px;
      line-height: 30px;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);


      .btn {
        display: inline-block;
        vertical-align: top;
        position: relative;
        width: 30px;
        height: 30px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        font-size: 20px;
        -webkit-transition: background-color 0.1s, color 0.1s;
        -o-transition: background-color 0.1s, color 0.1s;
        -moz-transition: background-color 0.1s, color 0.1s;
        transition: background-color 0.1s, color 0.1s;

        &:hover,
        &.active {
          background-color: #1a1a1a;
          color: #0a87ff;
        }
      }


      .group {
        display: inline-block;
        vertical-align: top;

        + .group::before {
          display: inline-block;
          vertical-align: top;
          width: 1px;
          height: 12px;
          margin: 10px 5px 0;
          background-color: rgba(0, 0, 0, 0.3);
          content: "";
        }
      }
    }
  }
`;

const ListArea = styled.div`
  overflow: hidden;
  overflow-y: auto;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background: rgba(75, 241, 255, 0.89);
  }

  &::-webkit-scrollbar-track-piece {
    background: #1f1f1f;
  }
`;

const ListCaption = styled.ul`
  
`;

