import React from 'react';
import styled from "styled-components";
import {CaptionListProps} from "../../../types/caption";
import {useAtom} from "jotai";
import {captionItemState, captionListState} from "../../../states/caption";
import {playerState} from "../../../states/player";
import {timestamp2seconds} from "../../../helper/helper";

type CaptionDataProps = {
  captionData: CaptionListProps
}

/**
 자막 데이터 (글)

 * @param data1 설명
 * @return {data1} 반환
 * @throws ValidException 예외 발생
 * @author plaq99@rumpus.co.kr
 * @date 03-20-2023
 */

export default function CaptionData({captionData}:CaptionDataProps) {

  const [, setPlayer] = useAtom(playerState);
  const [captionList, setCaptionList] = useAtom(captionListState);
  const [, setCaptionItem] = useAtom(captionItemState);

  const setCaption = (seq:number, target:string, value:string) => {

    const captionUpdated : any = [...captionList];
    captionUpdated[seq-1][target] = value ;
    setCaptionList(captionUpdated);
  }


  return (
    <DataArea>
      <div className="txt_area">
        <label htmlFor="caption_txt1" className="hide">자막</label>
        <textarea
          className="input_text type_align_left"
          name="caption"
          placeholder="자막을 입력하세요"
          maxLength={100}
          defaultValue={captionData.caption}
          onClick={()=>setCaptionItem({currentCaptionId:captionData.seq})}
          onDoubleClick={()=>setPlayer((prev)=>({...prev, seekOnTime:timestamp2seconds(captionData.time_ini), currentTime:timestamp2seconds(captionData.time_ini)}))}
          onChange={(e) => setCaption(captionData.seq, "caption", e.target.value)}
        ></textarea>
      </div>
    </DataArea>
  );
}

const DataArea = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  z-index: 10;
  padding: 0 5px 0 0;

  .txt_area {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    position: relative;
    height: 100%;
    padding: 0 0 0 5px;
    line-height: 25px;
    text-align: center;

    .input_text {
      height: 100%;
      padding: 12px;
      font-size: 17px;
      overflow: hidden;
      overflow-y: auto;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -moz-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 3px;
        border-radius: 3px;
        background: #3e3e3e;
      }

      &::-webkit-scrollbar-track-piece {
        background: #1f1f1f;
      }

    }

  }
`;
