import React, {lazy, Suspense} from 'react';
import styled from "styled-components";
import {Spinner} from "../../helper/spinner";

type ModalBoxProps = {
  contentName: string
}

/**
 팝업 노출 (공지, 공유, 상품 리스트)

 * @param data1 설명
 * @return {data1} 반환
 * @throws ValidException 예외 발생
 * @author plaq99@rumpus.co.kr
 * @date 03-20-2023
 */

export default function ModalBox({contentName}: ModalBoxProps) {

  // 동적 모달 콘텐츠 불러오기
  let ContentName = lazy(() => import(`./Contents/${contentName}`));

  return (
    <>
      <ModalArea>
        <Suspense fallback={Spinner()}>
          <ContentName/>
        </Suspense>
      </ModalArea>
    </>
  )
    ;
}


const ModalArea = styled.div`
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.6);

  &.on {
    display: block;
  }

  .modal_wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 360px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background-color: #2b2b2b;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .modal_header {
    padding: 0 20px;
    font-size: 17px;
    font-weight: bold;
    color: #fff;
    line-height: 50px;

    .btn_close {
      position: absolute;
      top: 0;
      right: 0;
      width: 50px;
      height: 50px;
      font-size: 20px;
    }
  }

  .modal_content {
    display: flex;
    justify-content: space-around;
    padding: 30px 20px;
    background-color: #1a1a1a;
  }


  &#modal_ai_translate .modal_wrapper {
    width: 80%;
  }

  &#modal_ai_translate .modal_content {
    /*overflow: hidden;*/
    padding: 20px;
  }

  &#modal_ai_translate .translate_area_wrapper:not(.swiper_area) {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }

  &#modal_ai_translate .translate_area:not(.swiper-slide) {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center;
  }

  &#modal_ai_translate .translate_area:not(.swiper-slide) + .translate_area {
    margin-left: 10px;
  }

  &#modal_ai_translate .platform {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 50px;
    font-size: 18px;
    font-weight: 800;
  }

  &#modal_ai_translate .list_ai_translate {
    margin-top: 10px;
    /*overflow: hidden;*/
    min-height: 10vh;
    overflow-y: auto;
    max-height: 400px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    background-color: rgba(255, 255, 255, 0.03);
    font-size: 14px;
    text-align: left;
    user-select: all;
  }

  &#modal_ai_translate .list_ai_translate::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &#modal_ai_translate .list_ai_translate::-webkit-scrollbar-thumb {
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background: #0a87ff;
  }

  &#modal_ai_translate .list_ai_translate::-webkit-scrollbar-track-piece {
    background: #1f1f1f;
  }

  &#modal_ai_translate .list_ai_translate .item {
    padding: 10px;
  }

  &#modal_ai_translate .list_ai_translate .item .info {
    user-select: text;
  }

  &#modal_ai_translate .list_ai_translate .item .title {
    color: #5e5e5e;
    user-select: text;
  }

  &#modal_ai_translate .list_ai_translate .item + .item {
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    user-select: all;
  }

  &#modal_ai_translate .btn_select {
    margin-top: 10px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: #0a87ff;
    font-weight: bold;
    line-height: 40px;

    &.close {
      background: #ff0000;
    }

  }

`;
